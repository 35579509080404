import { getButton } from './ElsaParticlesButtons';


//------------- ELSA VIDEO IMPORT -------------
import test from './elsa404.mp4';
import hello from './hello.mp4';
import hello_hd from './hello_hd.mp4';
import how_can_we_help_you from './how_can_we_help_you.mp4';
import do_you_need_help from './do_you_need_help.mp4';


import about_lc from './about_lc.mp4';
import about_lc_minion_1 from './about_lc_minion_1.mp4';
import about_lc_minion_2 from './about_lc_minion_2.mp4';
import about_lc_endline from './about_lc_endline.mp4';

import id_love_to_tell_you_about_offer from './id_love_to_tell_you_about_offer.mp4';
import look_around_for_possibilities from './look_around_for_possibilities.mp4';
import show_me_what_you_are_interest_in from './show_me_what_you_are_interest_in.mp4';
import logo from './logo.mp4';
import cards from './cards.mp4';
import carads from './carads.mp4';
import layouts from './layouts.mp4';
import graphicservice_labels from './graphicservice_labels.mp4';
import marketing from './marketing.mp4';
import googleads from './googleads.mp4';
import facebookads from './facebookads.mp4';
import socialmedia from './social_media.mp4';
import googlemybusiness from './googlemybusiness.mp4';


import code_main from './code_main.mp4';
import website from './website.mp4';
import website_annualfee from './website_annualfee.mp4';
import website_example from './website_example.mp4';

import module_bookingcalendar from './module_bookingcalendar.mp4';


import app_annualfee from './app_annualfee.mp4';
import graphicdesign_main from './graphicdesign_main.mp4';
import estore from './estore.mp4';
import estore_annualfee from './estore_annualfee.mp4';



//---------- ELSA FUNCTION ----------------- 
function elsa(sentence, lang){
    
    //---------- ELSA SENTENCES -------
    const elsaSentences = {
        hei_im_elsa: {
            file: hello,
            text: {
                no: 'Hei jeg heter Elsa. Jeg er Laskowski Creatives virtuelle assistent.',
                pl: 'Cześć jestem Elsa. Jestem wirtualną asystenktą Laskowski Creative.',
                en: 'I\'m Laskowski Creative\'s virtual assistant.',
            },
            type: 'introduce',
            buttons: ['continue']
        },
        test: {
            file: test,
            text: {
                no: 'Beklager, systemtesting pågår. Jeg vet ikke alt ennå.                         ',
                pl: 'Przepraszamy, trwają testy systemu. Nie wiem jeszcze wszystkiego.                ',
                en: 'I apologize, system testing is in progress. I don\'t know everything yet.            '
            },
            type: 'test',
            buttons: ['mainmenu']
        },

        
        hei_im_elsa_hd: {
            file: hello_hd,
            text: {
                no: 'Hei jeg heter Elsa. Jeg er Laskowski Creatives virtuelle assistent.',
                pl: 'Cześć jestem Elsa. Jestem wirtualną asystenktą Laskowski Creative.',
                en: 'I\'m Laskowski Creative\'s virtual assistant.',
            },
            type: 'introduce',
            buttons: ['continue']
        },      
        id_love_to_tell_you_about_offer: { 
            file: id_love_to_tell_you_about_offer,
            text: {
                no: 'Jeg kan gjerne fortelle deg om våre tilbud.', 
                pl: 'Chętnie opowiem Ci więcej o naszej ofercie.', 
                en: 'I\'ll gladly tell you more about our offer.', 
            },
            type: 'introduce',
            buttons: [
                'graphicdesign',
                'code',
                'marketing',
                'fotovideo',
                'supportit'
            ]
        },
        how_can_we_help_you: { 
            file: how_can_we_help_you,
            text: {
                no: 'Hvordan kan vi hjelpe deg?',
                pl: 'Jak możemy Ci pomóc?',
                en: 'How can we help you?',
            },
            type: 'helper',
            buttons: [
                'graphicdesign',
                'code',
                'marketing',
                'foto',
                'supportit'
            ]
        },
        do_you_need_help: { 
            file: do_you_need_help,
            text: {
                no: 'Trenger du hjelpe deg?',
                pl: 'Potrzebujesz pomocy?',
                en: 'Do you need help?',
            },
            type: 'helper',
            buttons: [
                'graphicdesign',
                'code',
                'marketing',
                'foto',
                'supportit'
            ]
        },
        look_around_for_possibilities: { 
            file: look_around_for_possibilities,
            text: {
                no: 'Se deg rundt, det er mange muligheter.',
                pl: 'Rozejrzyj się jest wiele możliwości.',
                en: 'Look around, there are many possibilities.',
            },
            type: 'helper',
            buttons: [
                'graphicdesign',
                'code',
                'marketing',
                'foto',
                'supportit'
            ]
        },
        show_me_what_you_are_interest_in: {
            file: show_me_what_you_are_interest_in,
            text: {
                no: 'Vil du vise meg hva du er interessert i?',
                pl: 'Wskaż co Cię interesuje.',
                en: 'Show me what you are interested in',
            },
            type: 'helper',
            buttons: [
                'offer',
                'aboutus',
                'kontakt',
            ]
        },
        about_lc: {
            file: about_lc,
            text: {
                no: 'Vi er et kreativt byrå. Hos Laskowski Creative kjenner vi alltid trendene og innoverer. Hvis du ønsker å eksistere på Internett - har du kommet til rett sted.',
                pl: 'Jesteśmy agencją kreatywną. W Laskowski Creative zawsze znamy aktualne trendy i wykorzystujemy innowacje. Jeżeli chcesz zaistnieć w Internecie. dobrze trafiłeś.',  
                en: 'We\'re a creative agency. At Laskowski Creative, we always know the current trends and innovate. If you want to exist on the Internet. you\'ve come to the right place.',
            },
            type: 'introduce',
            buttons: ['continue']
        },
        about_lc_minion_1: {
            file: about_lc_minion_1,
            text: {
                no: 'Kjernen i vårt firma er Damian - MSc of Software Engineering, han kan lage et nettsted eller programmer din bedrift trenger.',
                pl: 'Filarem naszej firmy jest Damian – mgr Inżynierii Oprogramowania, stworzy dla Ciebie stronę internetową lub program, który Twoja firma potrzebuje.',  
                en: 'The core of our company is Damian - MSc of Software Engineering, he can create a website or program that your company needs.',
            },
            type: 'introduce',
            buttons: ['continue']
        },
        about_lc_minion_2: {
            file: about_lc_minion_2,
            text: {
                no: 'Vi har også Margaret – som er grafisk designer og fotograf. Har du en visjon? Margaret kan lage et hvilket som helst grafisk design for deg.',
                pl: 'Jest też Małgosia – która jest grafikiem i fotografem. Masz swoją wizję? Pani grafik wykona dla Ciebie dowolny projekt graficzny.',  
                en: 'We have also Margaret – who is a graphic designer and photographer. Do you have your own vision? Margaret will make any graphic design for you.',
            },
            type: 'introduce',
            buttons: ['continue']
        },
        about_lc_endline: {
            file: about_lc_endline,
            text: {
                no: 'Vi er her for å hjelpe deg å eksistere på Internett.',
                pl: 'Jesteśmy dla Ciebie, aby pomóc Ci zaistnieć w Internecie.',  
                en: 'We are here to help you exist on the Internet.',
            },
            type: 'introduce',
            buttons: ['offer', 'kontakt', 'facebook', 'instagram', 'newsletter']
        },

//-------------- CODE -----------
        code_main: {
            file: code_main,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },

        code_integration: {
            file: false,
            text: {
                no: 'Vi kan lage en profesjonell og moderne nettside til deg, takket være den vil du kunne kommunisere med kundene dine.',
                pl: 'Stworzymy Twoją profesjonalną i nowoczesną stronę intern0etową, dzięki której będziesz mógł komunikować się ze swoimi klientami.',  
                en: 'We can create professional and modern website for you, thanks to which you will be able to communicate with your clients.',
            },
            type: 'code',
            buttons: ['website', 'app', 'estore', 'modulebookingcalendar']
        },

        website: {
            file: website,
            text: {
                no: 'Vi kan lage en profesjonell og moderne nettside til deg, takket være den vil du kunne kommunisere med kundene dine.',
                pl: 'Stworzymy Twoją profesjonalną i nowoczesną stronę intern0etową, dzięki której będziesz mógł komunikować się ze swoimi klientami.',  
                en: 'We can create professional and modern website for you, thanks to which you will be able to communicate with your clients.',
                },
            type: 'code',
            buttons: ['website', 'websiteprice', 'websiteannualfee', 'websitequick']
        },   
        website_quick: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'websitecopywright', 'websitequality', 'askforprice']
        },
        website_quality: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },        
        website_copywright: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },
        website_modules: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },   
        module_bookingcalendar: {
            file: module_bookingcalendar,
            text: {
                no: 'Nøkkelfunksjonalitet for hotellnettsteder, kosmetikk og medisinske tjenester.',
                pl: 'Kluczowa funkcjonalność stron hoteli, usług kosmetycznych i medycznych.',  
                en: 'Key functionality of hotel websites, cosmetic and medical services.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },
        module_virtualassistant: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },   
        module_contactform: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },  
        website_price: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'websiteannualfee', 'askforprice', 'offer']
        },        
        website_annualfee: {
            file: website_annualfee,
            text: {
                no: 'Den årlige avgiften er en del av budsjettet dedikert til vedlikehold av nettstedet. Kostnaden for administrasjon, server og webadresse for en nettside er i gjennomsnitt 2\'000 kr per år.',
                pl: 'Opłata roczna to składowa budżetu dedykowanego na utrzymanie strony internetowej. Koszt usługi administracji, miejsce na serwerze i adres internetowy dla strony internetowej wynosi średnio 2\'000 kr rocznie.',  
                en: 'The annual fee is a component of the budget dedicated to maintaining the website.The cost of the administration service, server space and web address for a website is on average 2\'000 kr per year.',
            },
            type: 'code',
            buttons: ['website', 'websiteprice', 'askforprice', 'kontakt']
        },
        website_adminpanel: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },  
        website_example: {
            file: website_example,
            text: {
                no: 'Se hjemmeside til vår siste kunde',
                pl: 'Zobacz stronę naszego ostatniego klienta',  
                en: 'See our last client\'s website',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },                 
        website_email: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },  
        estore: {
            file: estore,
            text: {
                no: 'Nettbutikker har blitt det viktigste kommunikasjonsmiddel for å nå nye målgruppen.',
                pl: 'Sklepy internetowe stały się najważniejszym i najczęściej stosowanym narzędziem pozwalającym dotrzeć do grupy docelowej.',  
                en: 'Online stores have become the most important and most frequently used tool to reach the target group.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },        
        estore_input: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },
        estore_adminpanel: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },  
        estore_price: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },                 
        estore_annualfee: {
            file: estore_annualfee,
            text: {
                no: 'Å drive nettbutikk er en større utfordring for IT-infrastrukturen. Derfor fører det vanligvis til høyere kostnad, som vi anslår etter foreløpig avtale med oppdragsgiver. Vanligvis ligger dette på det 3\'000 - 6\'000 kr per år.',
                pl: ' Prowadzenie sklepu internetowego to większe wyzwanie dla infrastruktury IT. W związku z tym zwykle wiąże się to z wyższymi kosztami, które szacujemy po wstępnych ustaleniach z klientem. Zwykle kosztuje to 3\'000 - 6\'000 kr rocznie.',  
                en: 'Operating an online store is a greater challenge for the IT infrastructure. Therefore, it usually requires a higher cost, which we estimate after preliminary arrangements with the client. Usually it costs 3\'000 - 6\'000 kr per year',
            },
            type: 'code',
            buttons: ['estoreprice', 'estore', 'askforprice', 'offer']
        },      
        app: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },         
        app_adminpanel: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['website', 'estore', 'app', 'kontakt']
        },  
        app_price: {
            file: false,
            text: {
                no: 'Vi designer nettsider, nettbutikker og applikasjoner.',
                pl: 'Projektujemy strony i sklepy internetowe i aplikacje. ',  
                en: 'We design websites, online stores and applications.',
            },
            type: 'code',
            buttons: ['app', 'appannualfee', 'app', 'askforoffer', 'offer']
        },                 
        app_annualfee: {
            file: app_annualfee,
            text: {
                no: 'For Internett-applikasjoner er det flere faktorer som påvirker prisen, inkludert antall funksjoner som refererer til serveren og databasene eller antall brukere per dag.',
                pl: 'W przypadku aplikacji internetowych na cenę wpływa kilka czynników, m.in. liczba funkcjonalności związanych z serwerem i bazami danych czy liczba użytkowników dziennie.',  
                en: 'For Internet applications, there are several factors affecting the price, including the number of functionalities referring to the server and databases or the number of users per day.',
            },
            type: 'code',
            buttons: ['app', 'appprice', 'estoreannualfee', 'askforoffer', 'offer']
        },   

        
//-------------- GRAPHIC DESIGN -----------
        graphicdesign_main: {
            file: graphicdesign_main,
            text: {
                no: 'Ta vare på bedriftens image og bestill ny logo. Velg en logo + visittkortpakke for rabatt.',
                pl: 'Zadbaj o wizerunek swojej firmy i zamów nowe logo. Wybierz pakiet logo + wizytówki i nie przepłacaj.',  
                en: 'Take care of your company\'s image and order a new logo. Choose a package of logo + business cards and save money.',
            },
            type: 'graphicdesign',
            buttons: ['logo', 'cards', 'carads', 'layouts', 'graphicservices']
        }, 
        logo: {
            file: logo,
            text: {
                no: 'Vi kan logodesign.',
                pl: 'Znamy się na projektowaniu logo.',  
                en: 'We know logo design.',
            },
            type: 'graphicdesign',
            buttons: ['cards', 'carads', 'packagelogocard', 'askforprice']
        }, 
        cards: {
            file: cards,
            text: {
                no: 'Et visittkort er den perfekte muligheten til å gjøre et godt førsteinntrykk.Valg av riktig papir, teknikker og grafikk mens du fortsatt er på designstadiet kan gjøre dette populære reklameverktøyet til et ekte mesterverk!',
                pl: 'Wizytówka to idealna okazja by zrobić dobre pierwsze wrażenie. Odpowiedni dobór papieru, technik i zabiegów graficznych jeszcze na etapie projektowania, potrafi uczynić z tego popularnego narzędzia reklamowego prawdziwy majstersztyk!',
                en: 'A business card is the perfect opportunity to make a good first impression. Proper selection of paper, techniques and graphics while still at the design stage can make this popular advertising tool a real masterpiece!',
                     },
            type: 'graphicdesign',
            buttons: ['logo', 'carads', 'packagelogocard', 'askforprice']
        }, 
        carads: {
            file: carads,
            text: {
               no: 'Bilreklame er den beste formen for utendørsreklame.',
               pl: 'Reklama na samochodzie to najlepsza forma reklamy zewnętrznej. ',
               en: 'Car advertising is the best form of outdoor advertising.',
            },
            type: 'graphicdesign',
            buttons: ['cards', 'logo', 'packagelogocardcar', 'askforprice']
        }, 
        layouts: {
            file: layouts,
            text: {
               no: 'Vi designer grafikk for en fanside på Facebook og andre nettsted. Vi henvender oss til hver ordre individuelt, i henhold til kundens retningslinjer. Vi sørger for at grafikken vises riktig både på datamaskin og på telefon.',
               pl: 'Zaprojektowanie grafiki na fanpage na facebooku lub stronie internetowej. Do każdego zamówienia podchodzimy indywidualnie, według wytycznych klienta. Dbamy o to by grafika wyświetlała się prawidłowo zarówno na komputerze jak i telefonie.',
               en: 'Designing graphics for a fanpage on Facebook or a website. We approach each order individually, according to the client\'s guidelines. We make sure that the graphics display correctly both on the computer and on the phone.',
            },
            type: 'graphicdesign',
            buttons: ['website', 'estore', 'socialmedia', 'askforprice']
        }, 
        graphicservices: {
            file: false,
            text: {
               no: '',
               pl: '',
               en: '',
            },
            type: 'graphicdesign',
            buttons: ['graphicserviceedit', 'graphicservicelabels', 'graphicserviceadsproducts', 'askforprice']
        }, 
        graphicservice_edit: {
            file: false,
            text: {
               no: '',
               pl: '',
               en: '',
            },
            type: 'graphicdesign',
            buttons: ['foto', 'fotosessionproduct', 'carads', 'askforprice']
        }, 
        graphicservice_labels: {
            file: graphicservice_labels,
            text: {
               no: 'Vi kan lage etiketter av høyeste kvalitet som fremheve ditt produktet.',
               pl: 'Stworzymy etykiety najwyższej jakości, które wyróżnią Twój produkt.',
               en: 'We can create the highest quality labels that will distinguish your product.',
            },
            type: 'graphicdesign',
            buttons: ['marketingbranding', 'estore', 'logo', 'askforprice']
        }, 
        graphicservice_adsproducts: {
            file: false,
            text: {
               no: '',
               pl: '',
               en: '',
            },
            type: 'graphicdesign',
            buttons: ['logo', 'marketingbranding', 'socialmedia', 'askforprice']
        }, 
//---------------- MARKETING ------------
        marketing_main: {
            file: marketing,
            text: {
                no: 'Vi tilbyr å konsolidere det grafiske inntrykket online for din bedriften for å hjelpe deg me å skape gjenkjennelig merkevare som blir likt.',
                pl: 'Zbudujemy i utrwalimy profesjonalny wizerunek Twojej firmy i pomożemy Ci stworzyć rozpoznawalną markę, która zyska polecenia.',  
                en: 'We will build and consolidate a professional image of your company to help you create a recognizable brand that will gain recommendations.',
            },
            type: 'marketing',
            buttons: ['marketingbranding', 'googleads', 'facebookads', 'socialmedia', 'googlemybusiness']
        },
        googleads: {
            file: googleads,
            text: {
                no: 'Ved å bruke mulighetene til Googles annonsenettverk kan du nå dine potensielle kunder. Presentasjon av tilbudet ditt i form av en interaktiv widget, grafikk, animasjon eller video lar deg nå en ny generasjon brukere.',
                pl: 'Dzięki wykorzystaniu możliwości sieci reklamowej Google, jesteś w stanie dotrzeć do swoich potencjalnych klientów. Prezentacja Twojej oferty w formie interaktywnego widgetu, grafiki, animacji lub wideo, pozwala sięgnąć do nowej generacji użytkowników.',
                en: 'By using the possibilities of the Google advertising network, you are able to reach your potential customers. Presentation of your offer in the form of an interactive widget, graphics, animation or video allows you to reach a new generation of users.'
            },
            type: 'marketing',
            buttons: ['googlemybusiness', 'facebookads', 'socialmedia', 'mittanbud' ]
        },        
        facebookads: {
            file: facebookads,
            text: {
                no: 'Aktiviteter i sosiale medier er ikke bare å drive en fanside og publisere innlegg. Facebook ADS-kampanjer er en mulighet til å nå de personene som best matcher kundens profil, det er en mulighet til å etablere verdifulle forretningskontakter, det er økt fortjeneste i e-handel.',
                pl: 'Działania w social mediach to nie tylko prowadzenie fanpage i publikowanie postów. Kampanie Facebook ADS to możliwość dotarcia do tych osób, które najlepiej pasują do rysu Twojego klienta, to możliwość nawiązania wartościowych kontaktów biznesowych, to większe zyski w e-commerce.',
                en: 'Activities in social media are not only running a fanpage and publishing posts. Facebook ADS campaigns are an opportunity to reach those people who best match your client\'s profile, it\'s an opportunity to establish valuable business contacts, it\'s more profits in e-commerce.'
            },
            type: 'marketing',
            buttons: ['marketingbranding', 'googleads', 'finn', 'socialmedia']
        },
        socialmedia: {
            file: socialmedia,
            text: {
                no: 'Folk elsker unike, sanne historier. Spesielt de der de kan være en del av det selv. Bruk det! Oppmuntre kunder til å samskape historier om bedriften din og bygge ditt eget fellesskap som kjenner, liker og anbefaler produktene eller tjenestene dine til andre.',
                pl: 'Ludzie kochają niepowtarzalne, prawdziwe historie. Zwłaszcza takie, w których sami mogą być częścią. Wykorzystaj to! Zachęć klientów do współtworzenia opowieści o Twojej firmie i zbuduj własną społeczność, która zna, lubi i rekomenduje innym Twoje produkty bądź usługi.',
                en: 'People love unique, true stories. Especially ones where they can be a part of it themselves. Use it! Encourage customers to co-create stories about your company and build your own community that knows, likes and recommends your products or services to others.'
            },
            type: 'marketing',
            buttons: ['marketingbranding', 'googleads', 'facebookads', 'googlemybusiness']
        },  
        googlemybusiness: {
            file: googlemybusiness,
            text: {
                no: 'Bedrifter som har bekreftet informasjon på Google-My Business er dobbelt så sannsynlig å bli ansett som pålitelige av forbrukere. Google My Business er sentralen for hvordan din bedrift oppfattes ved søke på nett og i Google Maps.',
                pl: 'Firmy, które posiadają zweryfikowane informacje w Google Moja Firma są dwa razy częściej uznawane przez konsumentów za godne zaufania. Google Moja Firma to centrum dowodzenia wizerunkiem Twojego biznesu w wyszukiwarce oraz w Mapach.',
                en: 'Businesses that have verified information on Google My Business are twice as likely to be considered trustworthy by consumers. Google My Business is the command center for the image of your business in the search engine and on Maps.'
            },
            type: 'marketing',
            buttons: ['marketingbranding', 'googleads', 'facebookads', 'socialmedia', 'mittanbud']
        },
        finn: {
            file: false,
            text: {
                no: '',
                pl: '',
                en: ''
            },
            type: 'marketing',
            buttons: ['mittanbud', 'googleads', 'facebookads', 'socialmedia', 'googlemybusiness']
        },        
        mittanbud: {
            file: false,
            text: {
                no: '',
                pl: '',
                en: ''
            },
            type: 'marketing',
            buttons: ['finn', 'googleads', 'facebookads', 'socialmedia', 'googlemybusiness']
        },
        marketingbranding: {
            file: false,
            text: {
                no: 'Vi vil lage en logo som er laget spesielt for deg, i samsvar med oppdraget og ideene til ditt merke. Din visuelle identitet vil være grunnlaget for å lage alle faglige budskap og bedriftsmateriell.',
                pl: 'Przygotujemy logotyp stworzony specjalnie dla Ciebie, w zgodzie z misją i ideami Twojej marki. Twoja identyfikacja wizualna będzie podstawą do tworzenia wszystkich profesjonalnych przekazów i materiałów firmowych.',
                en: 'We will prepare a logo that is made especially for you, in accordance with the mission and ideas of your brand. Your visual identity will be the basis for creating all professional messages and company material.'
            },
            type: 'marketing',
            buttons: ['googlemybusiness','logo', 'googleads', 'facebookads' ]
        },           

        fotovideo_main: {
            file: false,
            text: {
                no: 'Er du interessert i en fotoøkt, en fotodrone, eller trenger du en videoannonse? Vi skal gjøre det!',
                pl: 'Interesuje Cię sesja fotograficzna, fotografia dronem, albo potrzebujesz reklamy wideo? Zajmiemy się tym!',  
                en: 'Are you interested in a photo session, drone photography, or you need a video ad? We can do it!',
            },
            type: 'foto',
            buttons: ['fotosession', 'videosession', 'videoads', 'graphicservices']
        },
        foto: {
            file: false,
            text: {
                no: 'Trenger du en fotoøkt eller fotodrone? Vis meg hva du er interessert i?',
                pl: 'Interesuje Cię sesja fotograficzna lub fotografia dronem? Wskaż co Cię interesuje!',  
                en: 'Are you interested in a photo session or drone photography? Select what you are interested in?',
            },
            type: 'foto',
            buttons: ['fotosessiondrone', 'fotosessionproduct','fotosessionfamily', 'fotosessionquick' ]
        }, 
        fotosessions: {
            file: false,
            text: {
                no: 'Fotografering kan deles opp på grunn av teknologi, f.eks. digital, drone; eller på grunn av formålet, som familie, reklame.',
                pl: 'Fotografia może dzielić się ze względu na technologię, np. cyfrową, dronową lub ze względu na przeznaczenie, jak rodzinna, reklamowa.',
                en: 'Photography can be divided due to technology, e.g. digital, drone; or due to its purpose, such as family, advertising.',
            },
            type: 'foto',
            buttons: ['fotosessionproduct', 'fotosessionreports', 'fotosessionportrait']
        },
        fotosession_product: {
            file: false,
            text: {
                no: 'Produktfotografering er å promotere en gitt tjeneste eller produkt. Hensikten med bildene er å vise det valgte produktet i det beste lyset og samtidig opprettholde et troverdig utseende. Vi bruker denne typen bilder til nettauksjoner og nettbutikker.',
                pl: 'Fotografia produktowa ma promować daną usługę lub produkt. Zadaniem zdjęć jest ukazanie wybranego przedmiotu w jak najlepszym świetle z zachowaniem wiarygodnego wyglądu. Tego typu zdjęcia wykorzystujemy do aukcji internetowych i sklepów online.',
                en: 'Product photography is to promote a given service or product. The purpose of the photos is to show the selected item in the best light while maintaining a credible appearance. We use these types of photos for online auctions and online stores.',
            },
            type: 'foto',
            buttons: ['fotosessiondrone', 'fotosessionarchitecture', 'finn', 'videoads', ]
        },               
        fotosession_portrait: {
            file: false,
            text: {
                no: 'Portrettfotografering tar portretter - det inkluderer både nærbilder, som viser ansiktet og overkroppen, samt ytterligere bilder som viser hele figuren.',
                pl: 'Fotografia portretowa to wykonywanie portretów - obejmuje zarówno zdjęcia z bliska, pokazujące twarz i tors, jak i dalsze, obrazujące całą sylwetkę.',
                en: 'Portrait photography is taking portraits - it includes both close-up photos, showing the face and torso, as well as further photos, showing the whole figure.',
            },
            type: 'foto',
            buttons: ['fotosessionquick', 'fotosessionfamily', 'fotosessiondrone', 'foto']
        },
        fotosession_reports: {
            file: false,
            text: {
                no: 'Rapporter fokuserer på naturlighet. De skal vise hva øynene ser, uten å bruke filtre, uskarpe og lete etter interessante perspektiver.',
                pl: 'Reportaże stawiają na naturalność, brak artyzmu. Mają pokazywać to, co widzą oczy, bez używania filtrów, rozmyć i szukania ciekawych perspektyw.',
                en: 'Reports focus on naturalness. They are supposed to show what the eyes see, without using filters, blurring and looking for interesting perspectives.',
            },
            type: 'foto',
            buttons: ['fotosession', 'videosession', 'videoads', 'fotosessiondrone']
        }, 
        fotosession_architecture: {
            file: false,
            text: {
                no: 'Arkitekturfotografering innebærer å ta bilder av bygninger ute og inne, samt fragmenter av dem som er spesielt viktige for betrakteren. Denne typen fotografering kan tolkes som produktfotografering, avhengig av formålet med bildene.',
                pl: 'Fotografia architektury obejmuje wykonywanie zdjęć budynkom na zewnątrz i wewnątrz, jak ich fragmenty, które są szczególnie istotne dla widza. Ten rodzaj fotografii może być interpretowany jako fotografia produktowa, w zależności od przeznaczenia zdjęć.',
                en: 'Architecture photography involves taking pictures of buildings outside and inside, as well as their fragments that are particularly important to the viewer. This type of photography can be interpreted as product photography, depending on the purpose of the photos.',
            },
            type: 'foto',
            buttons: ['fotosessiondrone', 'fotosessionproduct', 'fotosession', 'videosession']
        },
        fotosession_interior: {
            file: false,
            text: {
                no: 'Arkitekturfotografering innebærer å ta bilder av bygninger ute og inne, samt fragmenter av dem som er spesielt viktige for betrakteren. Denne typen fotografering kan tolkes som produktfotografering, avhengig av formålet med bildene.',
                pl: 'Fotografia architektury obejmuje wykonywanie zdjęć budynkom na zewnątrz i wewnątrz, jak ich fragmenty, które są szczególnie istotne dla widza. Ten rodzaj fotografii może być interpretowany jako fotografia produktowa, w zależności od przeznaczenia zdjęć.',
                en: 'Architecture photography involves taking pictures of buildings outside and inside, as well as their fragments that are particularly important to the viewer. This type of photography can be interpreted as product photography, depending on the purpose of the photos.',
            },
            type: 'foto',
            buttons: ['fotosessiondrone', 'fotosessionproduct', 'videosession', 'videoads']
        },  
        fotosession_family: {
            file: false,
            text: {
                no: 'Familiefotografering skal gjenspeile personlighetstrekk, atmosfæren ved møter og fokusere på alle eller individuelle familiemedlemmer. Det er delt opp i nyfødt-, bryllups- og barselfotografering.',
                pl: 'Fotografia rodzinna powinna oddawać cechy osobowościowe, klimat spotkań i skupiać się na wszystkich lub na pojedynczych członkach rodziny. Dzieli się ja na fotografię noworodkową, ślubną, ciążową.',
                en: 'Family photography should reflect personality traits, the atmosphere of meetings and focus on all or individual family members. It is divided into newborn, wedding and maternity photography.',
            },
            type: 'foto',
            buttons: ['fotosessionquick', 'fotosessionportrait', 'fotosessiondrone', 'offer']
        },        
        fotosession_event: {
            file: false,
            text: {
                no: 'Målet er å vise de viktigste øyeblikkene i arrangementet og gjenspeile atmosfæren og følelsene til deltakerne.',
                pl: 'Celem jest pokazanie najważniejszych momentów wydarzenia oraz oddanie klimatu, emocji uczestników. ',
                en: 'The aim is to show the most important moments of the event and reflect the atmosphere and emotions of the participants.',
            },
            type: 'foto',
            buttons: ['fotosessiondrone', 'fotosessionreports', 'fotosession', 'videosession', 'videoads']
        },
        fotosession_drone: {
            file: false,
            text: {
                no: 'Dronefotografering har blitt veldig populært. Enhetene brukes ofte ved arrangementer og bryllup samt på arkitekturfotosesjoner.',
                pl: 'Zdjęcia wykonywane z drona cieszą się dużą popularnością. Urządzenia wykorzystuje się często na eventach i weselach oraz na foto sesjach architektury.',
                en: 'Drone photography has become very popular. The devices are often used at events and weddings as well as at architecture photo sessions..',
            },
            type: 'foto',
            buttons: ['fotosessionreports', 'fotosessionarchitecture', 'fotosessionfamily', 'videosession', 'videoads']
        },               
        fotosession_quick: {
            file: false,
            text: {
                no: '600 kr - 20-minutters utendørs familieportrettfotoseanse i Oslo. Mottak av bilder på e-post innen en uke.',
                pl: '600 kr - 20 minutowa plenerowa sesja zdjęciowa typu rodzinnego portretowego na terenie Oslo. Odbiór zdjęć drogą mailową w przeciągu tygodnia.',
                en: '600 kr - 20 minute outdoor family portrait photo session in Oslo. Receipt of photos by e-mail within a week.',
            },
            type: 'foto',
            buttons: ['fotosessionfamily', 'fotosession', 'fotosessionportrait', 'offer', 'foto']
        },
        videosession: {
            file: false,
            text: {
                no: 'Hvis du vil starte din nettbedrift, trenger du grafikk, bilder og videoinnhold. Vi lager grafikk og videoer for nettsider, sosiale medier og andre digitale plattformer.',
                pl: 'Jeśli chcesz rozkręcić swój biznes w sieci potrzebujesz grafik, zdjęć i treści wideo. Tworzymy grafiki i filmy na potrzeby stron internetowych, mediów społecznościowych i innych platform cyfrowych.',
                en: 'If you want to start your online business, you need graphics, photos and video content. We create graphics and videos for websites, social media and other digital platforms.',
            },
            type: 'foto',
            buttons: ['fotosessionevent', 'fotosessionreports', 'videosession', 'videoads', 'fotosessiondrone']
        }, 
        video_ads: {
            file: false,
            text: {
                no: 'Filmen gjør det mulig å perfekt eksponere de viktigste fordelene. En godt utført bildefilm vil hjelpe kundene å forstå merkevaren bedre og øke selskapets salg.',
                pl: 'Film pozwala doskonale wyeksponować najważniejsze atuty. Dobrze zrealizowany film wizerunkowy pomoże klientom lepiej zrozumieć markę i zwiększyć sprzedaż firmy.',
                en: 'The film makes it possible to perfectly expose the most important advantages. A well-executed image film will help customers understand the brand better and increase the company\'s sales.',
            },
            type: 'foto',
            buttons: ['fotosessiondrone', 'fotosession', 'videosession', 'fotosessioninterior', 'fotosessionevent']
        },

        supportit_main: {
            file: false,
            text: {
                no: 'Vi er alltid her for å hjelpe!',
                pl: 'Zawsze służymy pomocą!',  
                en: 'We\'re always here to help!',
            },
            type: 'supportit',
            buttons: ['supportitbusiness', 'supportitremote', 'supportitfix', 'supportittraining']            
        },
        supportit_business: {
            file: false,
            text: {
                no: 'Vi tilbyr støtte for virksomheter på alle nivåer, fra serverpleie til pent ordnede kabler, rask fjernhjelp og IT-kurs.',
                pl: 'Zapewniamy wsparcie dla biznesu na każdej płaszczyźnie, od opieki nad serwerem po zgrabnie ułożone kable, szybka pomoc zdalna oraz szkolenia IT.',  
                en: 'We provide support for business on every level, from server care to neatly arranged cables, quick remote assistance and IT training.',
            },
            type: 'supportit',
            buttons: ['supportitremote', 'supportittraining', 'supportitprice' ,'offer', 'kontakt']            
        },    
        supportit_remote: {
            file: false,
            text: {
                no: 'En løsning for å gi fjernhjelp ved å se dataskjermen - etter kundens forespørsel. Alle handlinger som utføres under en samtale er synlige på skjermen. Tjenesten hjelper oss med å løse problemet ditt i driften av programvaren eller datamaskinen ved hjelp av Internett-tilkoblingen.',
                pl: 'Rozwiązanie służące do świadczenia zdalnej pomocy poprzez podgląd ekranu komputera – na żądanie klienta. Wszystkie czynności dokonywane podczas połączenia są widoczne na ekranie. Usługa pomaga za pomocą połączenia internetowego rozwiązać Twój problem w działaniu oprogramowania lub komputera.',  
                en: 'A solution for providing remote assistance by viewing the computer screen - at the customer\'s request. All actions performed during a call are visible on the screen. The service helps to solve your problem in the operation of the software or computer using the Internet connection.',
            },
            type: 'supportit',
            buttons: ['supportitbusiness','supportitfix', 'offer', 'kontakt']            
        },       
        supportit_fix: {
            file: false,
            text: {
                no: 'Vi diagnostiserer og reparerer utstyr for å håndtere ethvert problem! Vi tilbyr utprøvd maskinvare og programvare. Vi sørger for at IT-systemet ditt holdes i full stand hele døgnet!',
                pl: 'Diagnozujemy i naprawiamy sprzęt, aby poradzić sobie z każdym problemem! Oferujemy sprawdzony sprzęt i oprogramowanie. Dbamy o to, aby Twój system informatyczny był utrzymany w pełnej sprawności przez 24 godziny na dobę!',  
                en: 'We diagnose and repair equipment to handle any problem! We offer proven hardware and software. We ensure that your IT system is kept in full condition 24 hours a day!',
            },
            type: 'supportit',
            buttons: ['supportitremote', 'offer', 'kontakt', 'aboutus']            
        },    
        supportit_training: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'supportit',
            buttons: ['supportitbusiness', 'supportitremote', 'offer', 'kontakt']            
        },      
        supportit_price: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'supportit',
            buttons: ['offer', 'kontakt', 'aboutus', 'facebook']            
        },      
    
        package_logocard: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'package',
            buttons: ['offer', 'kontakt', 'aboutus', 'facebook']            
        },  
        package_logocardcar: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'package',
            buttons: ['offer', 'kontakt', 'aboutus', 'facebook']            
        }, 
        package_websiteads: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'package',
            buttons: ['offer', 'kontakt', 'aboutus', 'facebook']            
        }, 
        package_socialads: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'package',
            buttons: ['offer', 'kontakt', 'aboutus', 'facebook']            
        }, 
        package_socialadsfoto: {
            file: false,
            text: {
                no: 'Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne vår kunnskap.',
                pl: 'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą.',  
                en: 'Software and IT systems are work tools, we know how they work and we\'re happy to share our knowledge.',
            },
            type: 'package',
            buttons: ['offer', 'kontakt', 'aboutus', 'facebook']            
        },       
    };

    // ------------ ELSA BUTTONS GENERATE ------
    const buttonArray = elsaSentences[sentence]['buttons'];
    const buttons = buttonArray.map((button, index)=>{
        return getButton(button, lang);
    })

    // --------- REQUESTED ELSA SENTENCE GENERATE -----
    const requestedSentence = {
        text: elsaSentences[sentence]['text'][lang],
        file: elsaSentences[sentence]['file'],
        type: elsaSentences[sentence]['type'],
        buttons,
    }

    // ------- FINALLY RETURN REQUESTED ELSA SENTENCE -----
    return requestedSentence;

}
//---------- ELSA SENTENCES END ----------------- 





//----------------------------- STARA ELSA =======


export{elsa};
