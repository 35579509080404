import React, { Component } from "react";
import './App.css';
import AboutUs from './AboutUs';
import ContactPanel from './ContactPanel';
import Gallery from './Gallery';
import Preface from './Preface';

class MainContent extends Component {

  render (){      
    const siteContent = this.props.siteContent ? this.props.siteContent : [];
    return (
    <main>        
        {siteContent.length > 0 && siteContent.map((item, index)=>(    
        <div key={index} id={item.link} className='content'>
            {/*<div className='picture'>
                <img src={'./'+item.pictures.slider} alt={item.title}/>          
            </div>*/}
            <div className="description">
            <h1>{item.title}</h1>
                {item.description.map((description, idx)=>(
                    <p key={idx}>{description}</p>
                ))}
           </div>
        </div>
        ))}
        <AboutUs />
    </main>
    )}
}

export default MainContent;