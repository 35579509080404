import React, { Component } from 'react';
import ElsaLoading from './ElsaLoading';
import ElsaTextDisplay from './ElsaTextDisplay';


class ElsaScript extends Component {
	constructor (props) {
		super(props);
		this.state = {
		};
		this.chooseScene = this.chooseScene.bind(this);
	};

	chooseScene(event){
	const name = event.target.getAttribute('name');
	const id = +event.target.getAttribute('id');	
	this.props.chooseScene(name, id);
	}


	render() {
		let ElsaTextShown = this.props.elsaScript ? this.props.elsaScript : [''];
		const elsaButtons = this.props.elsaButtons ? this.props.elsaButtons : [];
		const done = this.props.scriptShown;
		return (
		<>
            <ElsaTextDisplay scriptShown={done} elsaScript={ElsaTextShown}/>
			{elsaButtons && elsaButtons.map((button, index)=>
				<>
					{done && <div id='elsa-continue'><p id={button.link === 'continue' ? button.link : button.link[1]} name={button.link === 'continue' ? button.link : button.link[0]} onClick={button.link === 'continue' ? this.props.changeScene : this.chooseScene}>{button.text}<ElsaLoading/></p></div>}
				</>
			)}
			</>
		)
	}
}

export default ElsaScript;