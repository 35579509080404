import React, { Component } from 'react';
import PicturesGallery from './PicturesGallery';

class Gallery extends Component {
	render() {
		const folderName = ['herringbone', 'parquet_charakter', 'parquet_prime'];
		const title = ['Engineered Herringbone CHARACTER', 'Hardwood Parquet CHARACTER', 'Hardwood Parquet PRIME']
		const photos = [
			[
				{file: 'herringbone_1.jpg', fill: 170, x: 50, y: 50},
				{file: 'herringbone_2.jpg', fill: 120, x: 50, y: 50},
				{file: 'herringbone_3.jpg', fill: 120, x: 50, y: 50},
				{file: 'herringbone_4.jpg', fill: 170, x: 50, y: 50}
			],
			[
				{file: 'parquet_1.jpg', fill: 170, x: 50, y: 50},
				{file: 'parquet_2.jpg', fill: 200, x: 50, y: 50},
				{file: 'parquet_3.jpg', fill: 170, x: 50, y: 50},
				{file: 'parquet_4.jpg', fill: 170, x: 50, y: 50}
			],
			[
				{file: 'parquet_1.jpg', fill: 170, x: 50, y: 50},
				{file: 'parquet_2.jpg', fill: 170, x: 50, y: 50},
				{file: 'parquet_3.jpg', fill: 170, x: 50, y: 50},
				{file: 'parquet_4.jpg', fill: 120, x: 50, y: 50}
			],
		];
		const description = [
			'Korn kan være rett og bølget.',
			'Naturlige fargevariasjoner.',
			'Flat saget.',
			'Kyssesapgodtgjørelse 1-10%.',
			'Lydige, sunne knop opp til 60-70mm.',
			'Knuter fylt, alle knuter er små og ikke åpne.',
			'Svarte knop opp til 30 mm.',
			'Endesprekker tillatt opptil 10 cm.',
			'Kissing Heart tillatt.'
		]
		
		return (
		<div className='gallery'>
            <h1>W ofercie</h1>
			<div>
				{folderName.map((gallery, index)=>(
					<PicturesGallery description={description} folderName={gallery} title={title[index]} photos={photos[index]}/>
				))}
			</div>
			
		</div>
		) 
	}
}

export default Gallery;