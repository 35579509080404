import React, { Component } from "react";
import './App.css';

import Cookies from 'universal-cookie';

class LangBar extends Component {
    constructor () {
        super();
        this.state = {
            isOpen: false,

            }
            this.openDropdown = this.openDropdown.bind(this);
            this.chooseLanguage = this.chooseLanguage.bind(this);
            this.setCookie = this.setCookie.bind(this);
        }

    openDropdown(){
        let isOpen = this.state.isOpen;
        this.setState({
            isOpen: !isOpen
        })
    }
    
    chooseLanguage(event){
            let lang =  event.target.getAttribute('name');
            let isOpen = this.state.isOpen;
        this.setState({
            isOpen: !isOpen
        }, this.props.setLanguage(lang))
    }
    
    setCookie(lang) {
		const cookies = new Cookies();
		let expires = new Date();
  	expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
		cookies.set('lang', lang, expires.toUTCString(), {path: '/'});
	}

  render () {      
    return (
    <div id='lang' className='lang-bar'>
            {!this.state.isOpen && <div className='lang-button'>
            <img src={'./lang/'+ this.props.lang +'.png'} alt='description' onClick={this.openDropdown}/>           
        </div>}
        {this.state.isOpen && <div className='lang-button'>
            <img id='en' src='./lang/en.png' name='en' onClick={this.chooseLanguage} alt='english'/>  
            <img id='no' src='./lang/no.png' name='no' onClick={this.chooseLanguage} alt='norsk'/>
            <img id='pl' src='./lang/pl.png' name='pl' onClick={this.chooseLanguage} alt='polski'/>            
        </div>}

    </div>
    )}
}

export default LangBar;