import React, { Component } from "react";
import './App.css';
import { Carousel } from 'react-bootstrap';

class HeadCarousel extends Component {

  render (){      
    const siteContent = this.props.siteContent ? this.props.siteContent : [];
    return (

    <Carousel fade pause='false' interval='3000'>
        {siteContent.length > 0 && siteContent.map((item, index)=>(
            <Carousel.Item key={index}>
                <a href={'#'+item.link}><img
                className="d-block w-100"
                src={"./" + item.pictures.slider}
                alt={item.title}
                /></a>
                <Carousel.Caption>
                    <h2>{item.title}</h2>
                    <p>{item.caption}</p>
                </Carousel.Caption>
            </Carousel.Item>
        ))}
    </Carousel>
    )}
}

export default HeadCarousel;