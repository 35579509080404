import React, { Component } from "react";
import './App.css';
import AboutUs from './AboutUs';
import ContactPanel from './ContactPanel';
import Gallery from './Gallery';
import Preface from './Preface';

class FullPage extends Component {

  render (){ 
    let headerColor = [
        'hsla(0, 0%, 100%, .6)',
        'hsla(0, 0%, 100%, .7)',
        'hsla(166, 25%, 85%, 1)',
        'radial-gradient(circle, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
        'radial-gradient(circle, rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(180deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(360deg, rgba(9,87,121,1) 0%, rgba(141,159,171,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(60deg,  rgba(141,159,171,1) 0%, rgba(9,87,121,1) 35%, rgba(3,25,39,1) 100%)',
        'linear-gradient(60deg, rgba(3,25,39,1) 0%,rgba(9,87,121,1) 35%, rgba(141,159,171,1) 100%)'];     
    const siteContent = this.props.siteContent ? this.props.siteContent : [];
    const gridTemplate = [
        '',
        '90%',
        '49.5% 49.5%',
        '32.7% 32.7% 32.7%',
        '24.3% 24.3% 24.3% 24.3%',
        '19.2% 19.2% 19.2% 19.2% 19.2%',
    ]
    return (
        <div>

            <main id="main" style={{
                gridTemplateColumns: siteContent ? siteContent['link'] === 'MarketingPage' ? gridTemplate[2] : siteContent['link'] === 'CodePage' ? gridTemplate[4] : siteContent['link'] === 'GraphicDesign' ? gridTemplate[5] : siteContent['link'] === 'FotoPage' ? gridTemplate[3] : siteContent['link'] === 'ITSupportPage' ? gridTemplate[3] : gridTemplate[2]: gridTemplate[2]}}>
                {siteContent && siteContent['page'].map((item, index)=>(    
                    <div key={index} className='content' style={{background: 'rgba(0,0,0,0.4)'}}>
                    {/*<div className='picture'>  
                        <img src={'./'+item.miniature.file} alt={item.title}/> 
                    </div>*/}
                    <div className="description">
                    <h1>{item.title}</h1>
                    <h3>{item.caption}</h3>
 
                </div>
                </div>
                ))}
            </main>
        </div>
    )}
}

export default FullPage;