import React, { Component } from 'react';
import LangBar from './LangBar';

class Footer extends Component {
	render() {
		const giveData = function (type){
			const data= {
				mail: 'post@laskowski.no',
				mobileString: '+47 48 65 16 66',
    			mobile: '+4748651666'
    	};
    	return data[type];
		}
		return (
		<div className='footer'>
			<div>		
				<p>Copyright 2022</p>
			</div>				
			<div className='footer-logo'>
				<img src='./lc_logo/lc_logo_mini.svg' alt="Laskowski Creative"/><img className='copyright-logo' src='./lc_logo/lc_type.svg' alt="Laskowski Creative"/>
			</div>
			<div className='footer-contact'>		
				<a href={'tel:'+giveData('mobile')}><p>{giveData('mobileString')}</p></a>
				<a href={'mailto:'+giveData('mail')}><p>{giveData('mail')}</p></a>
			</div>
		</div>
		) 
	}
}

export default Footer;