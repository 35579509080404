import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import LangBar from './LangBar';
import './stylesheets/popup.css'

class CookiesPopup extends Component {
	constructor (props) {
		super(props);
		this.state = {
			mainClass: "cookies-popup-disappear",
            backgroundClass: "cookies-background",
			followSharkContent: [],
			step: 0,
			loading: '/',
			fullSharkContent: ['','','','','F', 'o', 'l', 'l', 'o', 'w', ' ', 't', 'h', 'e', ' ', 'b', 'l', 'u', 'e', ' ', 's', 'h', 'a', 'r', 'k', ' ']
		};
    	this.setCookie = this.setCookie.bind(this);
	};

	componentDidMount(){
		this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '\\',
			   })
		  }, 75);  
		  this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '|',
			   })
		  }, 150);  
		  this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '-',
			   })
		  }, 190); 		  
		  this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '/',
			   })
		  }, 220);  

		this.interval = setInterval(() => {
			if (this.state.fullSharkContent.length > 0)
				{
					let followSharkContent = this.state.followSharkContent.concat(this.state.fullSharkContent.shift());
					let step = this.state.step;
					step++;
					this.setState({ 
						step,
						followSharkContent,
					})
				}
			
			else  
			{
				let step = this.state.step;
				if (step<30)
				{
					step++;
					this.setState({
						step
					})
				}
				else if (step===30)
				{
					step++;
					if(!this.props.cookiesAccepted)
						this.setState({
							mainClass: 'cookies-popup',
							step,
						})
					else
						this.setState({
							mainClass: 'cookies-popup-disappear',
							backgroundClass: '',
							step,
						})
				}

			}
	}, 120)}

	setCookie() {
		const cookies = new Cookies();
		let expires = new Date();
  	expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
		cookies.set('cookies-accepted', true, expires.toUTCString(), {path: '/'});
		this.setState({
            backgroundClass: '',
			mainClass: "cookies-popup-disappear"
		})
	}
	render() {
		return (
            <div>
                <div className={this.state.backgroundClass}></div>
				<code><div className='follow-shark' style={{display: this.state.mainClass==='cookies-popup' || this.state.backgroundClass === '' ? 'none' : ''}}><h1><span>{'LC:> '}</span>{this.state.followSharkContent}{this.state.loading}</h1></div></code>
                <div className={this.state.mainClass}>
                    <LangBar setLanguage={this.props.setLanguage} lang={this.props.lang}/>
                    <p>{this.props.cookiesPopup}</p>
                    <div className='lc-button' onClick={this.setCookie}>
                        {this.props.accept}
                    </div>
                </div>
                <div>
                </div>
            </div>
		) 
	}
}

export default CookiesPopup;