import React, { Component } from 'react';
import { Row, Col, Form, Button, FormControl } from 'react-bootstrap';


class AboutPage extends Component {
	render() {
		const giveData = function (name,type){
			const data= 
            { 
				damian: {
					mail: 'damian@laskowski.no',
	    		    mobile: '+4748651666'},
                gosia: {
                    mail: 'gosia@laskowski.no',
                    mobile: '+4748651666'}
            };
    	return data[name][type];
		}
		return (
			<div className='content'>
				<Row className='about-page justify-content-md-center'>
					<Col sm={{span:8}} >
						<h1>{this.props.about[0]}</h1>
						<h3>{this.props.about[1]}</h3>
						<h3>{this.props.about[2]}</h3>
						<p>{''}</p>
					</Col>
				</Row>

			</div>
		) 
	}
}

export default AboutPage;