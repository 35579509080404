import React, { Component } from 'react';

class ElsaPopup extends Component {
	render() {
        const elsa = this.props.elsa;
        const openElsa = this.props.openElsa;
		return (
		<div id='elsa-popup' style={{display: elsa ? 'none' : 'initial'}} onClick={openElsa}>
            <div id='elsa-miniature'></div>
		</div>
		) 
	}
}

export default ElsaPopup;