import React, { Component } from 'react';
import ElsaTextDisplay from './ElsaTextDisplay';


class ElsaLoading extends Component {
	constructor (props) {
		super(props);
		this.state = {
			loading: '/',
		};
	};



	componentDidMount(){
		this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '\\',
			   })
		  }, 75);  
		  this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '|',
			   })
		  }, 150);  
		  this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '-',
			   })
		  }, 190); 		  
		  this.interval = setInterval(() => {
			let followSharkContent = this.state.fullSharkContent
			this.setState({ 
				loading: '/',
			   })
		  }, 220);  
    }

	render() {
		return (
            this.state.loading
		) 
	}
}

export default ElsaLoading;