const langObject = {
    description: {
        pl: {
            marketing: [
                    'Tożsamość marki i promocja w Internecie',
                    'Social Media',
                ],
            code: [
                    'Projektowanie i realizacja stron internetowych',
                    'Witryny sklepów internetowych',
                    'Systemy rezerwacji online', 
                    'Integracja aplikacji z istniejącymi systemami'
                ],
            graphic: [
                'Logo', 
                'Wizytówki',
                'Oklejanie aut',
                'Produkty reklamowe',
                'Etykiety', 
                ],
            foto: [
                    'Fotografia i rejestracja wideo',
                    'Ujęcia dronem',
                    'Spoty reklamowe'       
                ],
            supportIT: [
                    'Wsparcie IT',                   
                    'Kopie zapasowe i odzyskiwanie danych',
                    'Szkolenia'
                ],
            },
        en: {
            marketing: [
                    'Brand identity and promotion on the Internet',
                    'Social Media',
                ],
            code: [
                    'Design and implementation of websites',
                    'Online store sites',
                    'Online booking systems',
                    'Application integration with existing systems'
                ],
            graphic: [
                'Logo',
                'Business cards',
                'Advertising on cars',
                'Advertising products',
                'Labels and tags',
                ],
            foto: [
                    'Photography and video recording',
                    'Drone photography',
                    'Advertising films'
                ],
            supportIT: [
                    'IT support',
                    'Backup and data recovery',
                    'Training'
                ],
            },
        no: {
            marketing: [
                "Merkevareidentitet og markedsføring på Internett",
                'Sosiale medier',
            ],
            code: [
                'Design og implementering av nettsider',
                'Nettbutikksider',
                'online bookingsystemer',
                "Applikasjonsintegrasjon med eksisterende systemer"
            ],
            graphic: [
                'Logo',
                'Visittkort',
                'Reklame på biler',
                'Reklameprodukter',
                'Etiketter',
                ],
            foto: [
                    'Fotografi og videoopptak',
                    'Dronefotografering',
                    'Reklamefilmer'
                ],
            supportIT: [
                    'IT-støtte',
                    'Sikkerhetskopiering og datagjenoppretting',
                    'Opplæring'
                ],
            },
    },
    content: {
        pl: {
            marketing: [
                    [
                        'Zoptymalizujemy i wypozycjonujemy Twoją stronę, dzięki czemu Twoja strona będzie widoczna po najpopularniejszych frazach kluczowych w Twojej branży.',
                        'Przeprowadzimy Google Ads w wynikach wyszukiwania, kampanie Google Shopping, kampanie w sieci reklamowej, Remarketing, reklamy na You Tube.'
                    ],
                    [
                        'Stworzymy Twoje profesjonalne konta firmowe w social media lub zoptymalizujemy już istniejące. Poprowadzimy je tak, aby zbudować silną markę i pozyskać nowych klientów!',
                        
                    ],
                ],
            code: [
                    [
                        'Posiadamy kwalifikacje umożliwiające nam tworzenie oprogramowania z pełną odpowiedzialnością za kontrolę i jakość na każdym z etapów życia oprogramowania.',
                        'Zapewniamy bezpieczną i niezawodną infrastrukturę serwerową realizującą funkcjonowanie serwisu i poczty e-mail klienta. ',
                    ],
                    [
                        'Sklepy internetowe stały się najważniejszym i najczęściej stosowanym narzędziem pozwalającym dotrzeć do grupy docelowej.',
                        'Pracę sklepu online można wspomóc różnego rodzaju platformami sprzedażowymi, dodając produkty do porównywarek cen czy tworząc aplikację mobilną na smartfony i tablety.',


                    ],
                    [
                        'Zapewniamy kompleksową obsługę w zakresie tworzenia aplikacji i innych nowoczesnych rozwiązań e-biznesu.',
                    ], 
                    [
                        'Zintegrowanie aplikacji zapewnia wymianę informacji między różnymi aplikacjami.',
                        'Celem jest stworzenie zunifikowanego systemu do obsługi organizacji, który zajmie się wszystkim: od obsługi klienta, po obsługę księgową, finansową i magazynową firmy.'
                    ]
                ],
            graphic: [
                ['Logo otaczają nas ze wszystkich stron i stanowią nieodłączny element naszego życia. Ludzie są wzorokowcami, to co widzą klienci jest ważną częscią firmy.'],
                ['Istotne jest, aby wizytówki były odpowiednio zaprojektowane i wydrukowane na wysokiej jakości papierze.'],
                ['Zajmujemy się profesjonalnym oklejaniem aut. Dzięki naszym reklamom będziesz bardziej widoczny dla Twoich klientów .'],
                ['Umożliwia dotarcie do szerokiego grona odbiorców w krótkim czasie. Odpowiedni dobór papieru, technik i zabiegów graficznych jeszcze na etapie projektowania, potrafi uczynić z tego popularnego narzędzia reklamowego prawdziwy majstersztyk!'],
                ['Stworzymy etykiety najwyższej jakości, które wyróżnią Twój produkt. Nasze etykiety mają dokładnie takie cechy, które klient potrzebuje, nawet jeśli są to specjalne etykiety o szczególnie wysokich wymaganiach.'],
                ],
            foto: [
                    [
                        'Specjalizujemy się w:',
                        '- zdjęciach architektury', 
                        '- wnętrz', '- reportażowych',
                        '- oraz na potrzeby e-commerce.'
                    ],
                    [
                        'Zdjęcia promocyjne z drona dla firm, agentów nieruchomości oraz deweloperów to nasza specjalność.',
                        'Inspekcja dachu dronem i innych trudno dostępnych miejsc to świetne narzędzie, które pomaga w odbiorze nieruchomości.',

                    ],
                    [
                        'Wideo marketing zapewnia rozpoznawalność i niejednokrotnie przewagę konkurencyjną. ',
                        'Dobrze zrealizowany film wizerunkowy, ułatwi klientom lepsze zrozumienie marki, a firmie zwiększy poziom sprzedaży. ',
                        'Film umożliwia doskonałe wyeksponowanie najważniejszych zalet.',

                    ]       
                ],
            supportIT: [
                    [
                        'Realizujemy diagnozowania i napraw sprzętu, aby uporać się z każdym problemem!  Oferujemy sprawdzony sprzęt i oprogramowanie',
                        'Zapewniamy, że Twój system informatyczny zostanie utrzymany w pełnej sprawności przez 24 godziny na dobę!',
                    ],
                    [  
                        'Kopie zapasowe muszą być gotowe do odtworzenia oryginalnych danych w przypadku ich utraty.',
                        'W przypadku awarii podejmiemy wszelkie środki by odzyskać Twoje dane.',
                    ],
                    [  
                        'Oprogramowanie i systemy informatyczne to narzędzia pracy, znamy ich działanie i z przyjemnością dzielimy się wiedzą na szkoleniach.',
                    ],  
                    
                ],
            },
        en: {
            marketing: [
                    [
                        'We will optimize and position your website, your website will be visible by the most popular key phrases in your industry.',
                        'We will carry out Google Ads in search results, Google Shopping campaigns, advertising network campaigns, Remarketing, ads on YouTube.'
                    ],
                    [
                        'We will create your professional corporate social media accounts or optimize the existing ones. We will lead them to build a strong brand and attract new customers! ',
                        
                    ],
                ],
            code: [
                    [
                        'We are qualified to develop software with full responsibility for control and quality at every stage of the software life.',
                        'We provide a safe and reliable server infrastructure for the functioning of the website and client\'s e-mail. ',
                    ],
                    [
                        "Online stores have become the most important and most frequently used tool to reach the target group.",
                        'The work of the online store can be supported by various types of sales platforms, adding products to price comparison websites or creating a mobile application for smartphones and tablets.',


                    ],
                    [
                        'We provide comprehensive services in the field of application development and other modern e-business solutions.',
                    ],
                    [
                        'Application integration ensures the exchange of information between different applications.',
                        "The goal is to create a unified system to support the organization that will take care of everything from customer service to accounting, financial and warehouse services for the company."
                    ]
                ],
            graphic: [
                ['Logos surround us and are an integral part of our lives. What customers see is an important part of the company\'s image. '],
                ['It is important that business cards are properly designed and printed on high-quality paper.'],
                ['We deal with professional vehicle advertising. Thanks to our advertisements you will be more visible to your customers. '],
                ['It allows you to reach a wide audience in a short time. Proper selection of paper, techniques and graphics while still at the design stage can make this popular advertising tool a real masterpiece! '],
                ['We will create the highest quality labels that will distinguish your product. Our labels have exactly the features that the customer needs, even if they are special labels with particularly high requirements. '],
                ],
            foto: [
                    [
                        'We specialize in:',
                        '- photos of architecture',
                        '- interior', '- reportage',
                        '- and for e-commerce.'
                    ],
                    [
                        'Promotional photos from a drone for companies, real estate agents and developers are our specialty.',
                        "Inspecting the roof with a drone and other hard-to-reach places is a great tool that helps with property acceptance.",

                    ],
                    [
                        'Video marketing provides recognition and a competitive advantage. ',
                        'A well-executed image film will make it easier for customers to better understand the brand, and the company will increase the level of sales. ',
                        "The film allows you to highlight the most important advantages.",

                    ]
                ],
            supportIT: [
                    [
                        'We diagnose and repair equipment to deal with any problem! We offer proven hardware and software ',
                        'We ensure that your IT system will be kept in full working order 24 hours a day!',
                    ],
                    [
                        'Backups must be ready to restore the original data in case of loss.',
                        'In the event of a failure, we will take all measures to recover your data.',
                    ],
                    [
                        'Software and IT systems are work tools, we know their operation and we are happy to share our knowledge during training sessions.',
                    ],
                    
                ],
            },
        no: {
            marketing: [
                    [
                        "Vi vil optimalisere og posisjonere nettstedet ditt, nettstedet ditt vil være synlig med de mest populære nøkkelsetningene i din bransje.",
                        "Vi vil utføre Google Ads i søkeresultater, Google Shopping-kampanjer, kampanjer i annonsenettverk, remarketing, annonser på YouTube."
                    ],
                    [
                        'Vi vil opprette dine profesjonelle sosiale mediekontoer for bedrifter eller optimere de eksisterende. Vi skal lede dem til å bygge en sterk merkevare og tiltrekke nye kunder! ',
                        
                    ],
                ],
            code: [
                    [
                        'Vi er kvalifisert til å utvikle programvare med fullt ansvar for kontroll og kvalitet på alle stadier av programvarens levetid.',
                        'Vi tilbyr en sikker og pålitelig serverinfrastruktur for funksjonen til nettstedet og klientens e-post. ',
                    ],
                    [
                        "Nettbutikker har blitt det viktigste og mest brukte verktøyet for å nå målgruppen.",
                        'Arbeidet til nettbutikken kan støttes av ulike typer salgsplattformer, legge til produkter på prissammenligningsnettsteder eller lage en mobilapplikasjon for smarttelefoner og nettbrett.',
                    ],
                    [
                        'Vi tilbyr omfattende tjenester innen applikasjonsutvikling og andre moderne e-business-løsninger.',
                    ],
                    [
                        'Applikasjonsintegrasjon sikrer utveksling av informasjon mellom ulike applikasjoner.',
                        "Målet er å lage et enhetlig system for å støtte organisasjonen som skal ta seg av alt fra kundeservice til regnskap, økonomi og lagertjenester for selskapet."
                    ]
                ],
            graphic: [
                ['Logoer omgir oss og er en integrert del av livene våre. Det kundene ser er en viktig del av selskapets image. '],
                ['Det er viktig at visittkort er riktig utformet og trykt på papir av høy kvalitet.'],
                ['Vi driver med profesjonell bilreklame. Takket være våre annonser vil du være mer synlig for kundene dine. '],
                ['Det lar deg nå et bredt publikum på kort tid. Riktig utvalg av papir, teknikker og grafikk mens du fortsatt er på designstadiet kan gjøre dette populære reklameverktøyet til et ekte mesterverk! '],
                ['Vi vil lage etiketter av høyeste kvalitet som vil skille produktet ditt. Våre etiketter har akkurat de egenskapene som kunden trenger, selv om det er spesialetiketter med spesielt høye krav. '],
                ],
            foto: [
                    [
                        'Vi spesialiserer oss på:',
                        '- bilder av arkitektur',
                        '- interiør', '- reportasje',
                        '- og for e-handel.'
                    ],
                    [
                        'Kampanjebilder fra en drone for selskaper, eiendomsmeglere og utviklere er vår spesialitet.',
                        "Å inspisere taket med en drone og andre vanskelig tilgjengelige steder er et flott verktøy som hjelper med eiendomsgodkjenning.",

                    ],
                    [
                        'Videomarkedsføring gir anerkjennelse og et konkurransefortrinn.',
                        'En godt utført bildefilm vil gjøre det lettere for kundene å forstå merkevaren bedre, og selskapet vil øke salgsnivået. ',
                        "Filmen lar deg fremheve de viktigste fordelene.",

                    ]
                ],
            supportIT: [
                    [
                        'Vi diagnostiserer og reparerer utstyr for å håndtere ethvert problem! Vi tilbyr utprøvd maskinvare og programvare ',
                        'Vi sørger for at IT-systemet ditt holdes i full stand 24 timer i døgnet!',
                    ],
                    [
                        'Sikkerhetskopier må være klare for å gjenopprette de originale dataene i tilfelle tap.',
                        'I tilfelle en feil, vil vi iverksette alle tiltak for å gjenopprette dataene dine.',
                    ],
                    [
                        "Programvare og IT-systemer er arbeidsverktøy, vi vet hvordan de fungerer og vi deler gjerne kunnskapen vår under treningsøktene."
                    ],
                    
                ],
            },
    },
    caption: {
        pl: {
            marketing: [
                    'Zbudujemy i utrwalimy profesjonalny wizerunek Twojej firmy i pomożemy Ci stworzyć rozpoznawalną markę, która zyska polecenia.',
                    'Zaplanujemy strategie komunikacji w social media oraz przeprowadzimy kampanie reklamowe, budujące trwałe relacje z Twoimi klientami.'
                ],
            code: [
                    'Stworzymy Twoją profesjonalną i nowoczesną stronę internetową, dzięki której będziesz mógł komunikować się ze swoimi klientami.',
                    'Oferujemy autorskie rozwiązania e-commerce.',
                    'Kluczowa funkcjonalność stron hoteli, usług kosmetycznych i medycznych', 
                    'Tak jak budowlańcy łączą różne materiały w niezwykłe budowle my potrafimy połączyć różne rozwiązania informatyczne w użyteczną całość.'
                ],
            graphic: [
                'Logo to dla klienta pierwsze wrażenie Twojej firmy',
                'To bardzo silne narzędzie reklamowe',
                'Auto firmowe to jeżdżąca reklama',
                'Ulotka to ponadczasowe narzędzie marketingowe',
                'Potrzebujesz projekt etykiety swojego produktu, metkę do rękodzieła?', 
                ],
            foto: [
                    'Wykonamy profesjonalne zdjęcia reklamowe.',
                    'Stworzymy piękne ujęcia z lotu ptaka w najlepszej jakości 4K',
                    'Video, to najskuteczniejsza forma reklamy!'       
                ],
            supportIT: [
                    'Szybka pomoc zdalna i telefoniczna',                   
                    'Wsparcie w kryzysowych sytuacjach',
                    'Wiedza to potęga'
                ],
            },
        en: {
            marketing: [
                    'We will build and consolidate a professional image of your company and help you create a recognizable brand that will gain recommendations.',
                    "We will plan social media communication strategies and conduct advertising campaigns to build lasting relationships with your clients."
                ],
            code: [
                    'We will create your professional and modern website, thanks to which you will be able to communicate with your clients.',
                    'We offer proprietary e-commerce solutions.',
                    'Key functionality of hotel websites, cosmetic and medical services',
                    "Just as builders combine different materials into extraordinary buildings, we are able to combine various IT solutions into a useful whole."
                ],
            graphic: [
                'The logo is the first impression of your company for the client',
                'It is a very powerful advertising tool',
                'A company car is a driving advertisement',
                'The flyer is a timeless marketing tool',
                'Need a label design for your product?',
                ],
            foto: [
                    'We will take professional advertising photos.',
                    'We will create beautiful aerial shots in the best 4K quality',
                    "Video is the most effective form of advertising!"
                ],
            supportIT: [
                    'Fast remote and telephone assistance',
                    'Support in crisis situations',
                    'Mighty knowledge'
                ],
            },
        no: {
            marketing: [
                    "Vi vil bygge og konsolidere et profesjonelt image av bedriften din og hjelpe deg med å skape en gjenkjennelig merkevare som vil få anbefalinger.",
                    "Vi vil planlegge kommunikasjonsstrategier for sosiale medier og gjennomføre reklamekampanjer for å bygge varige relasjoner med kundene dine."
                ],
            code: [
                    'Vi vil lage din profesjonelle og moderne nettside, takket være den vil du kunne kommunisere med kundene dine.',
                    'Vi tilbyr proprietære e-handelsløsninger.',
                    'Nøkkelfunksjonalitet til hotellnettsteder, kosmetikk og medisinske tjenester',
                    "Akkurat som byggherrer kombinerer ulike materialer til ekstraordinære bygninger, er vi i stand til å kombinere ulike IT-løsninger til en nyttig helhet."
                ],
            graphic: [
                'Logoen er førsteinntrykket av din bedrift for kunden',
                "Det er et veldig kraftig reklameverktøy",
                'En firmabil er en kjørereklame',
                'Flyeren er et tidløst markedsføringsverktøy',
                'Trenger du et etikettdesign for produktet ditt?',
                ],
            foto: [
                    'Vi vil ta profesjonelle reklamebilder.',
                    "Vi vil lage vakre luftbilder i den beste 4K-kvaliteten",
                    "Video er den mest effektive formen for annonsering!"
                ],
            supportIT: [
                    'Rask fjernkontroll og telefonassistanse',
                    'Støtte i krisesituasjoner',
                    'Kunnskap er makt'
                ],
            },
    },
    title: {
        pl: ['Marketing online', 'Kodowanie', 'Projektowanie graficzne' , 'Foto & Wideo', 'Wsparcie IT'],
        en: ['Online marketing', 'Code', 'Graphic design', 'Photo & Video', 'IT support'],
        no: ['Online marketing', 'Kode', 'Grafisk design', 'Foto & Video', 'IT-støtte'],
    },
    about: {
        pl: [
            'Agencja Kreatywna A-Z',
            'Projektujemy strony i sklepy internetowe, aplikacje mobile, systemy IT. Zajmujemy się wizerunkiem i promocją marki. Prowadzimy kampanie reklamowe w Internecie i mediach. Wykonujemy projekty graficzne: logo, wizytówki, etykiety, reklamy na samochodach. Usługi fotograficzne, zdjęcia dronem, reklamy wideo. Wsparcie IT i szkolenia.',
            'Każdy projekt jakiego się podejmiemy realizujemy z pasją dając z siebie 110%. Lubimy wyzwania i angażujemy się w powierzone zadanie. Wyznajemy zasadę, że zadowolony klient to nasza najlepsza wizytówka.'
        ],
        en: [
            'Creative Agency A-Z',
            'We design websites and online stores, mobile applications, IT systems. We deal with the image and promotion of the brand. We run advertising campaigns on the Internet and in the media. We make graphic designs: logos, business cards, labels, advertising on cars. Photography services, drone photos, video ads. IT support and training. ',
            'Every project we undertake is carried out with passion, giving 110% of ourselves. We like challenges and we engage in the entrusted task. We adhere to the principle that a satisfied customer is our best business card. '    
        ],
        no: [
            'Creative Agency A-Å',
              'Vi designer nettsider og nettbutikker, mobilapplikasjoner, IT-systemer. Vi tar for oss bildet og promoteringen av merkevaren. Vi kjører annonsekampanjer på internett og i media. Vi lager grafisk design: logoer, visittkort, etiketter, reklame på biler. Fototjenester, dronebilder, videoannonser. IT-støtte og opplæring.',
              'Hvert prosjekt vi gjennomfører utføres med lidenskap, og gir 110 % av oss selv. Vi liker utfordringer og engasjerer oss i den betrodde oppgaven. Vi følger prinsippet om at en fornøyd kunde er vårt beste visittkort.'
        ],
    },
    cookies: {
        pl: 'Na stronie Laskowski Creative my i nasi partnerzy używamy plików cookie do dostosowywania reklam i pomiarów. Możesz przeczytać więcej na ten temat w naszej polityce prywatności i naszej stronie z informacjami o plikach cookie.',
        en: 'On Laskowski Creative websites, we and our partners use cookies for personalization of ads and measurement. You can read more about this in our privacy policy and our cookie information page.',
        no: 'På Laskowski Creative nettsider bruker vi og våre partnere informasjonskapsler (cookies) for personlig tilpasning av annonser og måling. Du kan lese mer om dette i vår personvernpolicy og vår informasjonsside om informasjonskapsler.',
    },
    navigation: {
        pl: ['o nas', 'kontakt'],
        en: ['about us', 'contact us'],
        no: ['om oss', 'kontakt oss']
    },
    kontakt: {
        pl: ['imię', 'e-mail / tel', 'nr telefonu', 'treść'],
        en: ['name', 'e-mail / phone',   'phone', 'message'],
        no: ['navn', 'e-post / tlf', 'tlf', 'melding']
    },
    kontaktRules: {
        pl: 'Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w niniejszym formularzu zapytania przez Laskowski Creative w celu skontaktowania się z Państwem i udzielenia odpowiedzi na zadane pytania.',
        en: 'I accept the processing of my personal information given in this contact form by Laskowski Creative to contact you and answer the question asked.',
        no: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Laskowski Creative for å kontakte deg og svare på det stilte spørsmålet.'
    },
    accept: {
        pl: 'Rozumiem',
        en: 'I understand',
        no: 'Jeg forstår'
    }
    

}
  const chooseLanguage = (lang) =>
{
	let outputObject = {};
	for (let [collectionName, languagesCollections] of Object.entries(langObject)) {
		outputObject[collectionName] = languagesCollections[lang];
	}
	return outputObject;
}
export {chooseLanguage};