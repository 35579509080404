import React, { Component } from "react";
import './App.css';
import ContactForm from './ContactForm';
import {Col, Button } from 'react-bootstrap';

class ContactPanel extends Component {
    constructor () {
        super();
        this.state = {
            messageId: false,
            messageForm: {
                email: false,
                mobile: false,
                name: false,
                message: false,
                accept: false,
            }
        }
        this.saveValues = this.saveValues.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.clearMessageId = this.clearMessageId.bind(this);
    }
    saveValues(object) {
        this.setState({
            messageForm: {
                ...this.state.messageForm,
                ...object,
            }
        })
    }

    clearMessageId(){
        this.setState({
            messageId: false
        })
    }

    sendForm() {
    const messageForm = this.state.messageForm;
    const address = {
        local: 'http://127.0.0.1:3003/api/laskowski/sendcontactform', 
        global: 'https://laskowskicreative.no/api/laskowski/sendcontactform'}
    fetch(address.global, {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(messageForm)
    })
    .then((res) => res.json())      
    .then((json) => this.setState(
        {
        messageId: json.messageId, 
        messageForm: {
            email: false,
            mobile: false,
            name: false,
            message: false,
            accept: false,
        }
            }
        ))  
    }
  render (){      
    return (
    <div id='kontakt' className='content'>
        {/*<div className='picture'>
            <img src='./lc_ico/lc_contact.svg' alt='description'/>          
    </div>*/}
        {!this.state.messageId && <div >
            <h1>{this.props.contactHeader}</h1>
            <ContactForm contactRules={this.props.contactRules} contactForm={this.props.contactForm} sendForm={this.sendForm} saveValues={this.saveValues} {...this.state.messageForm}/>
        </div>}
        {this.state.messageId && <div >
            <h1>{this.props.thankYouHeader[0]}</h1>
            <h3>{this.props.thankYouHeader[1]}</h3>
            <Button ref={(el) => { this.nextButton = el; }} style={{borderRadius: 0, visibility: 'visible'}} as={Col} variant='light'  name='next' id='next' onClick={this.clearMessageId}>OK</Button>
        </div>}
    </div>
    )}
}

export default ContactPanel;